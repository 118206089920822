import * as React from "react"
import { Helmet } from 'react-helmet'
import Layout from '../components/layout';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import './about.scss'
import { graphql, useStaticQuery } from 'gatsby';
import Partners from '../components/shared/partners';

const AboutPage = () => {
    const { title } = useSiteMetadata();
    const data = useStaticQuery(graphql`
        query AboutQuery {
          about {
            partners {
                heading
                content
            }
            data_availability {
                heading
                content
                training_data {
                    heading
                    content
                }
                dynamic_world_data {
                    heading
                    content
                }
            }
          }
        }
    `).about;

    return (
        <main>
            <Helmet title={title}/>
            <Layout>
                <div className="about-page">
                    <div className="wrapper">
                        <section>
                            <h2>{data.partners.heading}</h2>
                            <div className="content" dangerouslySetInnerHTML={{ __html: data.partners.content }}></div>
                        </section>
                    </div>
                    <Partners />
                    <div className="wrapper">
                        <section>
                            <h2>{data.data_availability.heading}</h2>
                            <div className="content" dangerouslySetInnerHTML={{ __html: data.data_availability.content }}></div>
                        </section>
                        <section>
                            <h3>{data.data_availability.training_data.heading}</h3>
                            <div className="content" dangerouslySetInnerHTML={{ __html: data.data_availability.training_data.content }}></div>
                        </section>
                        <section>
                            <h3>{data.data_availability.dynamic_world_data.heading}</h3>
                            <div className="content" dangerouslySetInnerHTML={{ __html: data.data_availability.dynamic_world_data.content }}></div>
                        </section>
                    </div>
                </div>
            </Layout>
        </main>
    )
}

export default AboutPage
